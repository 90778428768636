'use client'

import { debugConfigStore } from './store'
import type { DebugConfig } from './store'
import type { ReactNode } from 'react'
import React from 'react'

const DebugConfigContext = React.createContext<DebugConfig>({
  enableCopyPaste: false,
})

export const DebugConfigProvider: React.FC<{
  config: DebugConfig
  children: ReactNode
}> = ({ config, children }) => {
  debugConfigStore.initializeFromServer(config)

  return (
    <DebugConfigContext.Provider value={config}>
      {children}
    </DebugConfigContext.Provider>
  )
}

export const useDebugConfig = (): DebugConfig => {
  return React.useContext(DebugConfigContext)
}
