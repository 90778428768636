export interface DebugConfig {
  enableCopyPaste: boolean
}

class DebugConfigStore {
  private static instance: DebugConfigStore
  private config: DebugConfig = {
    enableCopyPaste: false,
  }
  private isInitialized = false

  private constructor() {}

  public static getInstance(): DebugConfigStore {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!DebugConfigStore.instance) {
      DebugConfigStore.instance = new DebugConfigStore()
    }
    return DebugConfigStore.instance
  }

  public initializeFromServer(config: DebugConfig): void {
    if (this.isInitialized) {
      return
    }
    this.setConfig(config)
    this.isInitialized = true

    Object.freeze(this.config)
    Object.freeze(this)
  }

  private setConfig(config: DebugConfig) {
    this.config = config
  }

  public getConfig(): Readonly<DebugConfig> {
    return this.config
  }
}

// Export a frozen singleton instance of the store
export const debugConfigStore = DebugConfigStore.getInstance()

// Getters for non-React code
export const isDebugCopyPasteEnabled = (): boolean =>
  debugConfigStore.getConfig().enableCopyPaste
